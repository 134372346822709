import React from "react";
import { AppContextPropTypes } from "../../../../../propTypes";
import { Dict, FormMetricTypeProps, FormSubmitData } from "../../../../../ts/types";
import { HttpError, Translate } from "../../../../common";
import "./Form.scss";

export type FormProps = {
  inputs: FormMetricTypeProps[];
  trimInputs?: boolean;
  validateData?: (data: FormSubmitData) => ValidationErrorArgs;
  noValidate?: boolean;

  onSubmit: (data: FormSubmitData) => void;
  onFormChange?: (data: FormSubmitData) => void;
  onError?: () => void;
};

type ValidationErrorArgs = any; // TODO

type FormState = {
  data: FormSubmitData;
  blurState: Dict<boolean>;
  loading: boolean;
  finished: boolean;
  error: boolean;
  httpErrorCode: number | null;
  validationErrorArgs: ValidationErrorArgs;
};

export class Form extends React.Component<FormProps, FormState> {
  static contextTypes = AppContextPropTypes;

  mounted?: boolean;

  constructor(props: FormProps) {
    super(props);

    const data: FormSubmitData = {};
    const blurState: Dict<boolean> = {};

    props.inputs.forEach(({ name, value }) => {
      data[name] = value || "";
      blurState[name] = false;
    });

    this.state = {
      data,
      blurState,
      loading: false,
      finished: false,
      error: false,
      httpErrorCode: null,
      validationErrorArgs: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: FormProps) {
    const props = this.props;

    // update the form input value if a new value was received through nextProps
    for (let i = 0; i < nextProps.inputs.length; i++) {
      const { name, value } = nextProps.inputs[i];
      if (value !== props.inputs[i].value) {
        console.log(`updating value: ${name}: ${value} `);
        this.setState(
          (prevState) => ({
            data: { ...prevState.data, [name]: value },
          }),
          () => {
            if (this.props.onFormChange) {
              this.props.onFormChange(this.state.data);
            }
          }
        );
      }
    }
  }

  handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { props, state } = this;

    this.setState({ loading: true });
    try {
      this.setState({validationErrorArgs: null});
      if (props.validateData) {
        const validationErrorArgs = await props.validateData(state.data);
        if (validationErrorArgs) {
          this.setState({validationErrorArgs});
          this.setState({ loading: false });
          return false;
        } else {
          this.setState({validationErrorArgs: null});
        }
      }
      this.setState({ loading: false });
    } catch(e) {
      this.setState({ loading: false });
      throw e;
    }

    this.setState({ loading: true, error: false });

    try {
      const ret = await this.props.onSubmit(state.data);
      console.debug("Form onSubmit return value: ", ret);
      this.mounted && this.setState({ finished: true });
    } catch (e) {
      console.error(e);
      this.mounted && this.setState({ error: true });

      if (e && e.request) {
        this.setState({ httpErrorCode: e.request.status });
      }

      if (this.props.onError) {
        this.props.onError();
      }
    } finally {
      this.mounted && this.setState({ loading: false });
    }

    return false; // prevent form submission
  };

  handleInputChange = (e: any) => {
    e.preventDefault();

    let { name, type, value } = e.target;

    if (this.props.trimInputs && typeof value === "string") {
      value = value.trim();
    }

    if (type === "number") {
      value = parseFloat(value);
      if (isNaN(value)) {
        value = "";
      }
    }

    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          [name]: value,
        },
      }),
      () => {
        if (this.props.onFormChange) {
          this.props.onFormChange(this.state.data);
        }
      }
    );
  };

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  render() {
    const { props, state, context } = this;
    const { i18n } = context;

    return (
      <section className="Form">
        <form onSubmit={this.handleFormSubmit} noValidate={this.props.noValidate || false}>
          <table>
            <tbody>
              {props.inputs.map((input, inputIndex) => {
                const { labelPrefix, i18nLabel, i18nSuffix, widthPx = 200, ...inputProps } = input;

                const defaultInputProps = {
                  type: "text",
                  required: true,
                  autoFocus: true,
                  // autoComplete: "off",
                };

                const mergedInputProps = { ...defaultInputProps, ...inputProps };

                return (
                  <tr key={input.name} className="Form__InputContainer">
                    <td className="align-middle">
                      <label>
                        {labelPrefix}
                        <Translate msg={i18nLabel} />:
                      </label>
                    </td>

                    <td className="align-middle">
                      <input
                        className="form-control form-control-sm"
                        {...mergedInputProps}
                        onChange={this.handleInputChange}
                        onBlur={() =>
                          this.setState((prevState) => ({
                            blurState: { ...prevState.blurState, [input.name]: true },
                          }))
                        }
                        value={state.data[input.name]}
                        style={{ minWidth: "60px", width: `${widthPx}px` }}
                      />
                    </td>
                    <td className="align-middle">
                      {i18nSuffix && (
                        <span>
                          <Translate msg={i18nSuffix} />
                        </span>
                      )}
                    </td>
                    <td className="align-middle">
                      {inputIndex === props.inputs.length - 1 && (
                        <button type="submit" className="btn btn-primary" disabled={state.loading}>
                          <Translate msg="submit" />
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </form>
        {state.validationErrorArgs && (
          <div className="mt-3 alert alert-danger">{i18n(...state.validationErrorArgs)}</div>
        )}
        {state.error && <HttpError status={state.httpErrorCode || 0} />}
      </section>
    );
  }
}

export default Form;
